<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'GroupbuyingsList',
              }"
            >
              團購管理
            </b-breadcrumb-item>
            <b-breadcrumb-item active>逾期未取名單</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row mb-2">
        <div class="col-12 col-xl-8 d-flex items-center">
          <h4 class="font-weight-bold">逾期未取名單</h4>
        </div>
        <div class="col-12 col-xl-4">
          <b-input-group>
            <b-form-input
              placeholder="搜尋LINE暱稱、LINE User Token"
              v-model="search"
              @keyup.enter="handleSearch"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="handleSearch"
                ><i class="fa fa-search"></i
              ></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <div
        class="d-flex flex-column flex-xl-row mt-4 mb-3 justify-content-between"
      >
      </div>

      <b-table
        striped
        hover
        responsive
        :items="blacklists"
        :fields="fields"
        :busy="isLoading"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            class="ml-2"
            variant="secondary"
            :to="{
              name: 'BlacklisBlacklistHistories',
              params: {
                blacklistID: data.item.id,
              },
            }"
          >查看紀錄</b-button>
        </template>
        <template #cell(status)="data">
          <span v-if="data.item.ban_at != null" class="badge badge-danger">停權中</span>
        </template>
        <template #cell(avatar_url)="data">
          <b-img-lazy
            v-bind="mainProps"
            v-show="!!data.item.avatar_url"
            rounded
            :src="data.item.avatar_url"
          ></b-img-lazy>
        </template>
        <template #cell(ban_at)="data">
          <span style='color:red'>{{data.item.ban_at}}</span>
        </template>
      </b-table>

      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-pagination
          class="separated"
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="groupbuyList"
          @change="(page) => fetchBlacklists(page)"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import blacklistApi from "../../../apis/blacklist";

export default {
  data() {
    return {
      isLoading: false,
      search: "",
      blacklists: [],
      activeBranch: null,
      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1",
      },
      fields: [
        {
          key: "actions",
          label: "查看紀錄",
        },
        {
          key: "status",
          label: "狀態",
        },
        {
          key: "avatar_url",
          label: "頭像",
        },
        {
          key: "full_name",
          label: "LINE 暱稱",
        },
        {
          key: "outer_code",
          label: "LineUID",
        },
        {
          key: "branch_name",
          label: "分店名稱",
        },
        {
          key: "blacklist_count",
          label: "累計次數",
        },
        {
          key: "ban_at",
          label: "封鎖時間",
        },
        {
          key: "unban_at",
          label: "解鎖時間",
        },
      ],
      perPage: 10,
      currentPage: 1,
      total: 0,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      currentBranch: (state) => state.currentBranch,
    }),
  },
  mounted() {
    this.fetchBlacklists();

  },
  methods: {
    async fetchBlacklists(page) {
      try {
        this.isLoading = true;
        const { data } = await blacklistApi.getBlacklist(this.organization, {
          branch_id: this.currentBranch.id,
          query: this.search,
          page: page || this.currentPage,
          per_page: 10,
        });
        this.total = data.meta.total;
        this.perPage = data.meta.per_page;
        this.blacklists = data.data;
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },

    handleSearch() {
      this.fetchBlacklists();
    },

    changePage() {
      this.fetchBlacklists();
    },
  },
};
</script>
